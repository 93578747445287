$pimary-black: #101010;
$pimary-orange: #fd5a1e;
$primary-white: #ffffff;

.val-home_sec {
    min-height: 100vh;
    height: auto;
    @media only screen and (max-width: 1024px) {
        height: auto !important;
        min-height: auto !important;
    }
}
  
  @media screen and (max-width: 630px) {
    .image {
      width: 60px;
      height: 50px;
    }
  }
.val-about {
    background-size: 100%;
    background-attachment: scroll;
    padding: 8% 0 5% 0%;

    @media only screen and (min-width: 767px) and (max-width: 1480px) {
        background-size: 100%;
        background-attachment: scroll;
        padding: 8% 0 5% 0%;
        background-position: bottom left;
        background-repeat: no-repeat;
        background-color: #8b8b8b;
    }

    @media only screen and (max-width: 767px) {
        background-repeat: no-repeat;
        background-color: #717171;
        padding-top: 270px;
        background-size: 200%;
        background-attachment: scroll;
        padding: 360px 15px 10% 15px;
        background-position: 3% -10%;
        .container {
            padding: 0 !important;
        }
    }
    h5 {
        text-align: left;
        font: normal normal 600 18px/23px Arvo;
        letter-spacing: 3.6px;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 1;
        @media only screen and (max-width: 767px) {
            padding-top: 15px;
        }
    }
    
    .val-about_right {
        h2 {
            text-align: left;
            font: normal normal bold 50px/65px Arvo;
            letter-spacing: 0px;
            text-transform: uppercase;
            opacity: 1;
            color: #ffffff;
            @media only screen and (max-width: 767px) {
                font: normal normal bold 30px/36px Arvo;
                margin-bottom: 10px !important;
            }
        }

        p {
            text-align: left;
            font: normal normal normal 30px/39px Arvo;
            @media only screen and (max-width: 1600px) {
                font: normal normal normal 20px/39px Arvo;
            }
            @media only screen and (max-width: 767px) {
                font-family: "Open+Sans", sans-serif !important;
                font-size: 14px;
                line-height: 30px;
                font-weight: 300;
                margin-bottom: 10px !important;
            }
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
        }

        .secondary_cta {
            margin-left: -14px;
        }
    }
}
