
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	font-family: 'Open+Sans', serif !important;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
a {
	text-decoration: none !important;
	cursor: pointer;
}
.card-container p {
	color: #000 !important;
	text-align: center;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
body {
	margin: 0;
	padding: 0;
	font-family: "Open+Sans", sans-serif !important;
}

$pimary-black: #101010;
$pimary-orange: #fd5a1e;
$primary-white: #ffffff;

.primary-orange {
	color: $pimary-orange;
}
.primary-black {
	color: $pimary-black;
}
.primary-white {
	color: $primary-white;
}
.primary_cta {
	text-align: left;
	font: normal normal 600 18px/23px Open+Sans;
	letter-spacing: 0px;
	color: #fd5a1e !important;
	text-transform: uppercase;
	padding: 14px 20px 14px 20px !important;
	opacity: 1;
	transition: 0.5s;
	border-radius: 30px;
	&:hover {
		background-color: #fd5a1e !important;
		color: #fff !important;
		padding: 14px 20px 14px 20px !important;
		transition: 0.5s;
		border-radius: 30px;
		img {
			filter: brightness(0) invert(1);
			border: none;
			left: 0;
			transition: 0.5s !important;
			width: 40px;
			vertical-align: middle;
		}
	}
	img {
		width: 40px;

		border: 1px solid #d9e1eb;
		border-radius: 100%;
		padding: 10px;
		position: relative;
		left: -16px;
		transition: 0.5s !important;
		vertical-align: middle;
	}
}

.secondary_cta {
	text-align: left;
	font: normal normal 600 18px/23px Open+Sans;
	letter-spacing: 0px;
	color: #fff !important;
	text-transform: uppercase;
	padding: 14px 20px 14px 20px !important;
	opacity: 1;
	transition: 0.5s;
	border-radius: 30px;
	&:hover {
		background-color: #fd5a1e !important;
		color: #fff !important;
		padding: 14px 20px 14px 20px !important;
		border-radius: 30px;
		transition: 0.5s !important;
		img {
			border: none;
			left: 0px;
			transition: 0.5s !important;
			vertical-align: middle;
		}
	}
	img {
		filter: brightness(0) invert(1);
		width: 50px;
		vertical-align: middle;
		border: 1px solid #d9e1eb;
		border-radius: 100%;
		padding: 10px;
		position: relative;
		left: -16px;
		transition: 0.5s;
	}
}

p {
	text-align: left;
	font: normal normal normal 18px/45px Open+Sans;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
}
h3 {
	text-align: left;
	font: normal normal bold 50px/65px Open+Sans !important;
	letter-spacing: 0px;
	text-transform: uppercase;
	opacity: 1;
}
h5 {
	text-align: left;
	font: normal normal 600 18px/23px Open+Sans;
	letter-spacing: 3.6px;
	color: #ffffff;
	text-transform: uppercase;
	opacity: 1;
}
.form-control:focus {
	color: #212529;
	background-color: transparent !important;
	border: none !important;
	outline: 0 !important;
	border: none !important;
	border-bottom: 1px solid #fff !important;

	box-shadow: none !important;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.rotating {
	-webkit-animation: rotating 2s linear infinite;
	-moz-animation: rotating 2s linear infinite;
	-ms-animation: rotating 2s linear infinite;
	-o-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;

	display: inline-block;
	height: 40px;
	width: 30px;
	@media only screen and (max-width: 767px) {
		animation: NONE !important;
	}
}
@-moz-keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		-moz-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-moz-transform: translateY(-30px);
		transform: translateY(-30px);
	}
	60% {
		-moz-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}
@-webkit-keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}
	60% {
		-webkit-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}
@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-moz-transform: translateY(-30px);
		-ms-transform: translateY(-30px);
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}
	60% {
		-moz-transform: translateY(-15px);
		-ms-transform: translateY(-15px);
		-webkit-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}

.infinate {
	-moz-animation: bounce 2s infinite;
	-webkit-animation: bounce 2s infinite;
	animation: bounce 2s infinite;
}
.footer {
	P {
		font-size: 15px !important;
	}
}
.btn-close {
	background: none !important;
	opacity: 1 !important;
	box-sizing: content-box;
	width: 110px !important;
	height: 90px !important;
}

.breadcumb-area {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), no-repeat center/cover;
	height: 450px;
	position: relative;
	max-height: 411px;
	@media only screen and (max-width: 767px){
		height: auto;
	position: relative;
	max-height: 100%;
	}
	img {
		max-height: 411px;
		width: 100%;
		object-fit: cover;
		object-position: center;
		@media only screen and (max-width: 767px){
			height: auto;
			position: relative;
			max-height: 150px;
		}
	}
}
.breadcumb-content {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.breadcumb-content h1 {
	font-size: 45px;
	color: #fff;
	margin-bottom: 20px;
	@media only screen and (max-width: 767px){
		font-size: 30px;
		color: #fff;
		margin-bottom: 0px;
		padding-top: 21px;
	}

}
.breadcumb-content ul li {
	font-size: 14px;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
	display: inline-block;
	margin: 0 5px;
	@media only screen and (max-width: 767px){
		font-size: 12px;
	}
}
.breadcumb-content ul li a {
	font-size: 14px;
	color: #fff;
	text-transform: uppercase;
	@media only screen and (max-width: 767px){
		font-size: 12px;
	}
	
}
ol,
ul,
dl {
	padding: 0;
	margin: 0;
}

.contact_from_box {
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 27px 40px 27px;
}
.form_box input {
    width: 100%;
    height: 54px;
    border: 1px solid #e6e6e6;
    padding-left: 15px;
	border-radius:5px;
}
.form_box textarea {
    width: 100%;
    height: 150px;
    padding: 10px 0 0 15px;
	border: 1px solid #e6e6e6;
	border-radius:5px;
}
.quote_btn button {
    background: #FF3C00;
    padding: 14px 39px;
    color: #fff;
}
.appointment-content-section .quote_btn button {
    width: 100%;
}
.appointment-section.style-2 .form_box textarea {
    height: 115px;
}
.quote_btn button:hover{
	background:#bd2d10;
	color:#fff;
}
.form_box input:focus{
  color: #141414;
  background-color: #fff !important;
  border-color: #FF3C00 !important;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(241,42,2,0.25) !important;
}
.form_box textarea:focus{
  color: #141414;
  background-color: #fff !important;
  border-color: #00ff40 !important;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(241,42,2,0.25) !important;
}
.breadcumb-area:before {
    content: "";
    width: 100%;
    height: 410px;
    position: absolute;
    left: 0;
    top: 0;
    background-color:  #00000091;
    z-index: 1;
	@media only screen and (max-width: 767px){
		height: auto;
	}
}
.breadcumb-content{
	z-index: 2;
}

.hr {
    border-top: 1px solid rgba(238, 238, 238, 1);
}
.contact-us .appoinment-title {
  text-align: left;
}
.contact-us .input-box {
  width: 40%;
  float: left;
  margin: 8px 15px;
  background: #fff;
}
.contact-us .appoinment-title {
  margin: 0 15px;
} 
.contact-us .input-box.textarea{
  width: 85%;
}
.contact-us .ap-submit-btn {
  width: 30%;
  float: left;
  margin: 15px;
}
.appointment-content {
  height: 100%;
}
.appointment-content .appointment-form {
  height: 100% !important;
  padding: 0 0 0 40px;
}
.cda-icon i {
	font-size: 32px;
    color: #fff;
    height: 60px;
    width: 60px;
    line-height: 70px;
    text-align: center;
    background: #Ff3C00;
    border-radius: 5px;
    display: inline-block;
    margin-right: 25px;
    margin-top: 10px;
}
.cda-content-inner h4 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0;
}
.cda-content-inner p{
  color: #141414;
  margin: 15px 0;
}
.cda-content-area{
  background: #141414;
  padding: 20px 0 0 0;
}
.cda-single-content {
 margin: 0 45px;
 padding: 25px 0;
}
.contact-us .appoinment-title h3 {
  margin-bottom: 20px;
  padding-top: 35px;
  font-weight: 500;
}
.contact-us .input-box {
  color: #000;
}
::placeholder{
	color: #15171a;
}
.subscribe-section-icon {
	color:#FF3C00;
	font-size: 50px;
  }
  input.subscribe-mail-box {
    border: 1px solid #adadad;
}
  .subscribe-section-title h5{
	text-transform: capitalize;
    color: #000000;
    font-size: 24px;
    font-weight: 600;
    padding-left: 25px;
    margin: 20px 0;
    line-height: 26px;
    letter-spacing: 0px;
  }
  .subscribe-input-field {
   position: relative;
  }
  .subscribe-mail-box {
	width: 100%;
	padding: 16px 0px 10px 11px;
	border-radius: 5px;
	border: #fff;
  }
  .subscribe-submit-btn {
	border: 1px solid #FF3C00;
	border-radius: 3px;
	background: #FF3C00;
	color: #fff;
	font-size: 17px;
	font-weight: 600;
	position: absolute;
	top: 18px;
	right: 5px;
	padding: 10px 30px;
	transition: .3s;
  }
  .subscribe-submit-btn:hover {
	background: #b32a00;
  }
  .pricing-section.style-two {
	  background: #f4f7fc;
  }
  .contact-card {
    box-shadow: 0px 0px 40px 0px #0000001a;
    background: #fff;
    padding: 25px;
    clear: both;
}

.contact-card li {
    display: flex;
}
.contact-card li .icon {
    margin-right: 10px;
}

.contact-card h5{
	color: #FF3C00;
}
.list-style-bullet{
list-style:disc;
list-style-position:outside;
margin-left:20px;
li{
	margin-top:10px;
	color: #141414;
    font-family: "Open+Sans", sans-serif !important;
    /* font-size: 17px; */
    /* line-height: 25px; */
    /* font-weight: 500; */
    margin-bottom: 10px;
}
}