.val-industry {
    background-size: 100%;
    background-attachment: fixed;
    padding: 8% 0 5% 0%;

    @media only screen and (max-width: 767px)  {
        padding: 9% 0;
        .container{
            padding: 0 !important;
            .row {
            margin: 0 !important;
            }
        }
    }
    
    .secondary_cta {
        margin-left: 30px;
        @media only screen and (max-width: 767px) {
            margin-left: 15px;
        }
    }
    .val-industry_content {
        h5 {
            text-align: left;
            font: normal normal 600 18px/23px Arvo;
            letter-spacing: 3.6px;
            color: #ffffff;
            text-transform: uppercase;
            opacity: 1;
            @media only screen and (max-width: 767px) {
                margin-bottom: 20px !important;
                padding-left: 10px;
            }
        }
        h2 {
            text-align: left;
            font: normal normal bold 80px/100px Arvo;
            letter-spacing: 0px;
            text-transform: uppercase;
            opacity: 1;
            color: #ffffff;
            padding-left: 50px;
            @media only screen and (max-width: 767px) {
                padding-left: 10px;
                font: normal normal bold 30px/36px Arvo;
                margin-bottom: 10px !important;
            }
        }
        p {
            text-align: left;
            font: normal normal normal 30px/45px Arvo;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            padding-left: 50px;
            @media only screen and (max-width: 767px) {
                font-family: "Open+Sans", sans-serif !important;
                font-size: 18px;
                line-height: 30px;
                font-weight: 300;
                margin-bottom: 10px !important;
                padding-left: 15px;
                max-width: 90%;
            }
        }
    }
}
