
.single-service {
    background: rgba(242, 242, 242,0.1);
  }
  .uk-link-toggle:focus .uk-link, .uk-link-toggle:hover .uk-link, .uk-link:hover, a:hover {
  
}
  .top-link a{
    color: #fff;
    background: #252628;
    font-size: 18px;
    display: block;
    padding: 13px 18px;
    position: relative;
    margin-bottom: 30px;
  }
  .top-link a:hover{
    color: #fff;
   background-color: #000;
  }
  .top-link a i{
    position: absolute;
    right: 16px;
    top: 30%;
    font-size: 18px;
    color: #fff;
  }
  .top-link a:hover i{
    color: #fff;
  }
  .pack-title h5 {
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 0;
    margin-top: 10px;
  } 
  .get-pack a{
    color: rgbaa(86,86,86,.8);
  } 
  .pack-icon i{
    color: #fd5a1e;
    font-size: 50px;
    margin-right: 25px;
  }
  .single-pack {
    background: #fff;
    padding: 18px 25px 25px;
    margin-bottom: 30px;
    border-radius: 5px;
   
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  }
  ul.social {
    display: flex;
    justify-content: space-between;
    max-width: 160px;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 0 !important;
}

ul.social li {
    border: 1px solid #fff;
    padding: 5px;
    line-height: 16px !important;
    border-radius: 3px;
}
  .cda-content h4 {
    font-size: 25px;
    font-weight: 500;
    color: #fff;
  }
  .cda-content h3,   .cda-content a {
    font-size: 16px !important;
    color: #fff;
    text-align: left;
    line-height: 26px !important;
    font-weight: 600;
    font-family: 'Open+Sans', sans-serif !important;

}
ul.social li a{
  line-height: 16px !important;
}
.cda-content {
  transition: 0.3s all ease;
}
.cda-content:hover {
  background: #2f2e41;
  transition: 0.3s all ease;
}
.cda-content a:hover{
  color: #ffffffb8;
}

  .cda-content {
    background: #fd5a1e;
    padding: 1px 18px 19px 22px;
  }
  .overview-title {
    font-size: 25px;
    margin-top: 25px;
  }
  .section-thumb img {
    width: 100%;
    border: 1px solid #dddddd9e;
    box-shadow: 0 0 35px #0000001a;
  }
  .pack-icon img{
      max-width: 55px;
      margin-right: 25px;
  }
  .pack-title h5 {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0;
    margin-top: 10px;
    color: #1a1a1a;
    letter-spacing: 0;
    line-height: 21px
}
.get-pack a {
    color: rgba(86,86,86,.8);
}
.overview-content p{
    color: #1a1a1a;
    font-family: 'Open+Sans', sans-serif !important;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    padding-top: 5px;
}
.pointment-single-feature {
  text-align: center;
  background: #f4f7fc;
  border-radius: 10px;
  margin-top: 30px;
 padding-bottom: 10px;
}
.ap-feature-icon {
  font-size: 55px;
  color: #FF3C00;
  padding: 20px 0 15px 0;
  img{
    height: 55px;
    width: 55px;
    
  }
}
.ap-feature-content p{
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #1a1a1a;
  text-align: center;
  font-family: 'Open+Sans', sans-serif !important;
   
  margin-bottom: 20px;
}
.ap-feature-title h4 {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  color: #1a1a1a;

}
@media only screen and (max-width: 767px) {
  .single-service {
    padding-top: 9%;
    .row{
      flex-direction: column-reverse;
    }
    .section-thumb{
      margin-top: 9%;
    }
  }
  .contact-us{
    max-width: 100%;
  }
  .contact_from_box{
    padding: 10px  !important;
  }
  .cda-single-content{
    padding: 15px !important;
    flex-direction: column;
  }
  .cda-icon{
    margin-bottom: 20px;
  }
  
  
  
  .contact_from_box {
         h3{
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  
    }
  }
  form#contact_form{
    max-width: 100%;
  }
  .cda-single-content , .cda-content-inner p, .cda-content-inner h4{
    margin: 0 !important;
    line-height: 28px;
  }
}
.contact_from_box {
    background-color: #c1c0c0; /* Light background color */
    padding: 20px; /* Add padding for spacing */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 15px rgba(255, 1, 1, 0.1); /* Subtle shadow */
}

.list-style-bullet {
  line-height: 1.5; /* Adjusts line spacing */
  margin-bottom: 15px; /* Space between list items */
}

.list-style-bullet li {
  margin-bottom: 10px; /* Space below each list item */
}
