#val-header {
    padding: 20px 50px 17px 50px;
    transition: 0.3s all ease;

    &.sticky_nav {
        position: fixed;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        padding: 16px 50px 10px 50px;
        transition: 0.3s all ease;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        @media only screen and (max-width: 760px) {
            padding: 15px 15px 15px 15px;
        }
    }

    @media only screen and (max-width: 760px) {
        padding: 15px 15px 15px 15px;
        border-bottom: 1px solid #ddd;
    }

    .offcanvas-end {
        background-color: #ffffff;
        width: 30%;
    }

    .val-header_left {
        img {
            height: 60px;

            @media only screen and (max-width: 760px) {
                height: 45px;
            }
        }
    }

    .val-header_center {
        @media only screen and (max-width: 1025px) {
            display: none;
        }

        .szh-menu {
            left: 0 !important;
        }

        display: flex;
        margin-bottom: 0;

        .mega-dropdown:hover {
            .mega-dropdown__body {
                opacity: 1;
                visibility: visible;
                transition: opacity 0.10s, visibility 0.10s;
            }
        }

        .mega-dropdown {
            position: relative;

            &__body {
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.10s, visibility 0.10s;
                position: absolute;
                top: 24px;
                left: 0;
                overflow: visible;
                background: #fff;
                padding: 10px 20px !important;
                border: 1px solid #ddd;
                height: auto;
                min-width: 280px;
                z-index: 4;
                box-shadow: 0 0 3px #00000033 !important;

                li {
                    border-bottom: 1px solid #b9b9b9;
                    line-height: 25px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    transition: 0.3s all ease;

                    &:hover {
                        transition: 0.3s all ease;
                        padding-left: 10px;

                        a {
                            color: #fd5a1e;
                            font-weight: 700;

                        }
                    }

                    a {
                        font-size: 14px;
                        font-family: "Open+Sans", serif !important;
                        padding-left: 0 !important;
                    }
                }
            }
        }

        li a,
        li p,
        .szh-menu-button,
        a {
            padding: 0 20px;
            text-decoration: none;

            text-align: left;
            font: normal normal 500 18px/23px Calibri;

            letter-spacing: 0px;
            color: #101010;
            opacity: 1;

            @media only screen and (min-device-width : 766px) and (max-device-width : 1220px) {
                font: normal normal 100 16px/23px Calibri;
                padding: 0 10px;
            }

            &.active {
                color: #fd5a1e;
            }
        }
    }

    .val-header_right {
        a {
            margin-right: 50px;

            @media only screen and (max-width: 1400px) {
                display: none;
            }
        }

        .primary_cta {
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }

        &.active {
            color: #fd5a1e;
        }

        .navbar-toggler {
            width: 45px;
            height: 40px;
            border: none !important;
            opacity: 1;

            &:focus {
                border: 1px dotted #000 !important;
                border-radius: 0;
                box-shadow: none !important;
            }

            .navbar-toggler-icon {
                background-image: none !important;

                img {
                    height: auto;
                    width: 30px;
                }
            }
        }
    }
}
.offcanvas {
    width: 50vw; /* Keeps width suitable for larger screens */
    max-width: 100%; /* Ensures responsiveness for smaller screens */
    height: 100vh; /* Full height */
    background-color: #fff; /* Background color */
    z-index: 1050;
    overflow-y: auto; /* Allows scrolling if content exceeds viewport */
}

.offcanvas-header {
    padding: 1rem;
    border-bottom: 1px solid #e6e6e6; /* Added border for better separation */
}

.offcanvas-header img {
    max-width: 100%; /* Ensures logo fits within the container */
    height: auto; /* Maintains aspect ratio */
    margin: 0 auto; /* Centers the image */
}

.offcanvas-body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left for better readability */
    font-size: 1.2rem; /* Suitable for mobile readability */
}

.navbar-nav {
    width: 100%; /* Ensures full width */
    margin: 0;
    padding: 0;
    list-style: none; /* Removes default list styling */
}

.navbar-nav .nav-item {
    margin-bottom: 1rem; /* Adjusts spacing between items */
}

.navbar-nav .nav-item:last-child {
    margin-bottom: 1; /* Removes bottom margin for the last item */
}

.nav-link-active {
    text-decoration: none; /* Removes underline */
    font-size: 1.5rem; /* Adjusts size for readability */
    color: #070707; /* Text color */
    display: block; /* Makes the entire area clickable */
    padding: 0.5rem 0; /* Adds padding for touch-friendly design */
}

.navbar-toggler-icon img {
    max-width: 2rem; /* Adjusted size for toggler icon */
    height: auto; /* Maintains aspect ratio */
}

.social {
    list-style: none; /* Removes default bullet points */
    padding: 0;
    margin: 1rem 0; /* Adds space above and below the social icons */
    display: flex;
    gap: 1rem; /* Adds consistent spacing between icons */
    justify-content: center; /* Centers icons horizontally */
}

.social a {
    color: #070707;
    font-size: 1.5rem; /* Icon size */
    transition: color 0.3s ease; /* Smooth hover effect */
}

.social a:hover {
    color: #ff6600; /* Highlight color on hover */
}
