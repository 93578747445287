ol,
ul {
  padding-left:0 !important;
}
.pt-0{
    padding-top: 0;
   }
   .pt-5{
    padding-top: 5px;
   }
   .pt-10{
    padding-top: 10px;
   }
   .pt-15{
    padding-top: 15px;
   }
   .pt-20{
    padding-top: 20px;
   }
   .pt-25{
    padding-top: 25px;
   }
   .pt-27{
    padding-top: 27px;
   }
   .pt-30{
    padding-top: 30px;
   }
   .pt-35{
    padding-top: 35px;
   }
   .pt-40{
    padding-top: 40px;
   }
   .pt-45{
    padding-top: 45px;
   }
   .pt-50{
    padding-top: 50px;
   }
   .pt-55{
    padding-top: 55px;
   }
   .pt-60{
    padding-top: 60px;
   }
   .pt-65{
    padding-top: 65px;
   }
   .pt-70{
    padding-top: 70px;
   }
   .pt-75{
    padding-top: 75px;
   }
   .pt-80{
    padding-top: 80px;
   }
   .pt-85{
    padding-top: 85px;
   }
   .pt-90{
    padding-top: 90px;
   }
   .pt-95{
    padding-top: 95px;
   }
   .pt-100{
    padding-top: 100px;
   }
   .pt-120{
    padding-top: 120px;
   }
   .pt-150{
    padding-top: 150px;
   }
   .pt-155{
    padding-top: 155px;
   }
   .pt-160{
    padding-top: 160px;
   }
   .pt-180{
    padding-top: 180px;
   }
   .pt-200{
    padding-top: 200px;
   }
   .pt-220{
    padding-top: 220px;
   }
   .pt-250{
    padding-top: 250px;
   }
   .pt-300{
    padding-top: 300px;
   }
   
   
   
   
   
   .pb-0{
    padding-bottom: 0;
   }
   .pb-5{
    padding-bottom: 5px;
   }
   .pb-10{
    padding-bottom: 10px;
   }
   .pb-15{
    padding-bottom: 15px;
   }
   .pb-20{
    padding-bottom: 20px;
   }
   .pb-25{
    padding-bottom: 25px;
   }
   .pb-30{
    padding-bottom: 30px;
   }
   .pb-35{
    padding-bottom: 35px;
   }
   .pb-40{
    padding-bottom: 40px;
   }
   .pb-45{
    padding-bottom: 45px;
   }
   .pb-50{
    padding-bottom: 50px;
   }
   .pb-55{
    padding-bottom: 55px;
   }
   .pb-60{
    padding-bottom: 60px;
   }
   .pb-65{
    padding-bottom: 65px;
   }
   .pb-70{
    padding-bottom: 70px;
   }
   .pb-75{
    padding-bottom: 75px;
   }
   .pb-80{
    padding-bottom: 80px;
   }
   .pb-85{
    padding-bottom: 85px;
   }
   .pb-90{
    padding-bottom: 90px;
   }
   .pb-95{
    padding-bottom: 95px;
   }
   .pb-100{
    padding-bottom: 100px;
   }
   .pb-110{
    padding-bottom: 110px;
   }
   .pb-120{
    padding-bottom: 120px;
   }
   .pb-140{
    padding-bottom: 140px;
   }
   .pb-150{
    padding-bottom: 150px;
   }
   .pb-170{
    padding-bottom: 170px;
   }
   .pb-180{
    padding-bottom: 180px;
   }
   .pb-200{
    padding-bottom: 200px;
   }
   .pb-220{
    padding-bottom: 220px;
   }
   .pb-230{
    padding-bottom: 230px;
   }
   .pb-235{
    padding-bottom: 235px;
   }
   .pb-250{
    padding-bottom: 250px;
   }
   .pb-300{
    padding-bottom: 300px;
   }
   .pb-360{
    padding-bottom: 360px;
   }
   
   
   
   
   .pr-0{
    padding-right: 0;
   }
   .pr-5{
    padding-right: 5px;
   }
   .pr-10{
    padding-right: 10px;
   }
   .pr-15{
    padding-right: 15px;
   }
   .pr-20{
    padding-right: 20px;
   }
   .pr-25{
    padding-right: 25px;
   }
   .pr-30{
    padding-right: 30px;
   }
   .pr-35{
    padding-right: 35px;
   }
   .pr-40{
    padding-right: 40px;
   }
   .pr-45{
    padding-right: 45px;
   }
   .pr-50{
    padding-right: 50px;
   }
   .pr-55{
    padding-right: 55px;
   }
   .pr-60{
    padding-right: 60px;
   }
   .pr-65{
    padding-right: 65px;
   }
   .pr-70{
    padding-right: 70px;
   }
   .pr-75{
    padding-right: 75px;
   }
   .pr-80{
    padding-right: 80px;
   }
   .pr-85{
    padding-right: 85px;
   }
   .pr-90{
    padding-right: 90px;
   }
   .pr-95{
    padding-right: 95px;
   }
   .pr-100{
    padding-right: 100px;
   }
   
   .pl-0{
    padding-left: 0;
   }
   .pl-5{
    padding-left: 5px;
   }
   .pl-10{
    padding-left: 10px;
   }
   .pl-15{
    padding-left: 15px;
   }
   .pl-20{
    padding-left: 20px;
   }
   .pl-25{
    padding-left: 25px;
   }
   .pl-27{
    padding-left: 27px;
   }
   .pl-30{
    padding-left: 30px;
   }
   .pl-35{
    padding-left: 35px;
   }
   .pl-40{
    padding-left: 40px;
   }
   .pl-45{
    padding-left: 45px;
   }
   .pl-50{
    padding-left: 50px;
   }
   .pl-55{
    padding-left: 55px;
   }
   .pl-60{
    padding-left: 60px;
   }
   .pl-65{
    padding-left: 65px;
   }
   .pl-70{
    padding-left: 70px;
   }
   .pl-75{
    padding-left: 75px;
   }
   .pl-80{
    padding-left: 80px;
   }
   .pl-85{
    padding-left: 85px;
   }
   .pl-90{
    padding-left: 90px;
   }
   .pl-95{
    padding-left: 95px;
   }
   .pl-100{
    padding-left: 100px;
   }
   
   .mt-0{
    margin-top: 0;
   }
   .mt-5{
    margin-top: 5px;
   }
   .mt-10{
    margin-top: 10px;
   }
   .mt-15{
    margin-top: 15px;
   }
   .mt-20{
    margin-top: 20px;
   }
   .mt-25{
    margin-top: 25px;
   }
   .mt-30{
    margin-top: 30px;
   }
   .mt-35{
    margin-top: 35px;
   }
   .mt-40{
    margin-top: 40px;
   }
   .mt-43{
    margin-top: 43px;
   }
   .mt-45{
    margin-top: 45px;
   }
   .mt-50{
    margin-top: 50px;
   }
   .mt-55{
    margin-top: 55px;
   }
   .mt-60{
    margin-top:60px;
   }
   .mt-65{
    margin-top: 65px;
   }
   .mt-70{
    margin-top: 70px;
   }
   .mt-75{
    margin-top: 75px;
   }
   .mt-80{
    margin-top: 80px;
   }
   .mt-85{
    margin-top: 85px;
   }
   .mt-90{
    margin-top: 90px;
   }
   .mt-95{
    margin-top: 95px;
   }
   .mt-100{
    margin-top: 100px;
   }
   
   
   .mb-0{
    margin-bottom: 0;
   }
   .mb-5{
    margin-bottom: 5px;
   }
   .mb-10{
    margin-bottom: 10px;
   }
   .mb-15{
    margin-bottom: 15px;
   }
   .mb-20{
    margin-bottom: 20px;
   }
   .mb-25{
    margin-bottom: 25px;
   }
   .mb-30{
    margin-bottom: 30px;
   }
   .mb-35{
    margin-bottom: 35px;
   }
   .mb-40{
    margin-bottom: 40px;
   }
   .mb-45{
    margin-bottom: 45px;
   }
   .mb-50{
    margin-bottom: 50px;
   }
   .mb-55{
    margin-bottom: 55px;
   }
   .mb-60{
    margin-bottom:60px;
   }
   .mb-65{
    margin-bottom: 65px;
   }
   .mb-70{
    margin-bottom: 70px;
   }
   .mb-75{
    margin-bottom: 75px;
   }
   .mb-80{
    margin-bottom: 80px;
   }
   .mb-85{
    margin-bottom: 85px;
   }
   .mb-90{
    margin-bottom: 90px;
   }
   .mb-95{
    margin-bottom: 95px;
   }
   .mb-100{
    margin-bottom: 100px;
   }
   
   .ml-0{
    margin-left: 0;
   }
   .ml-5{
    margin-left: 5px;
   }
   .ml-10{
    margin-left: 10px;
   }
   .ml-15{
    margin-left: 15px;
   }
   .ml-20{
    margin-left: 20px;
   }
   .ml-25{
    margin-left: 25px;
   }
   .ml-30{
    margin-left: 30px;
   }
   .ml-35{
    margin-left: 35px;
   }
   .ml-40{
    margin-left: 40px;
   }
   .ml-45{
    margin-left: 45px;
   }
   .ml-50{
    margin-left: 50px;
   }
   .ml-55{
    margin-left: 55px;
   }
   .ml-60{
    margin-left:60px;
   }
   .ml-65{
    margin-left: 65px;
   }
   .ml-70{
    margin-left: 70px;
   }
   .ml-75{
    margin-left: 75px;
   }
   .ml-80{
    margin-left: 80px;
   }
   .ml-85{
    margin-left: 85px;
   }
   .ml-90{
    margin-left: 90px;
   }
   .ml-95{
    margin-left: 95px;
   }
   .ml-100{
    margin-left: 100px;
   }
   .mr-0{
    margin-right: 0;
   }
   .mr-5{
    margin-right: 5px;
   }
   .mr-10{
    margin-right: 10px;
   }
   .mr-15{
    margin-right: 15px;
   }
   .mr-20{
    margin-right: 20px;
   }
   .mr-25{
    margin-right: 25px;
   }
   .mr-30{
    margin-right: 30px;
   }
   .mr-35{
    margin-right: 35px;
   }
   .mr-40{
    margin-right: 40px;
   }
   .mr-45{
    margin-right: 45px;
   }
   .mr-50{
    margin-right: 50px;
   }
   .mr-55{
    margin-right: 55px;
   }
   .mr-60{
    margin-right:60px;
   }
   .mr-65{
    margin-right: 65px;
   }
   .mr-70{
    margin-right: 70px;
   }
   .mr-75{
    margin-right: 75px;
   }
   .mr-80{
    margin-right: 80px;
   }
   .mr-85{
    margin-right: 85px;
   }
   .mr-90{
    margin-right: 90px;
   }
   .mr-95{
    margin-right: 95px;
   }
   .mr-100{
    margin-right: 100px;
   }
   
   .white h1,
   .white h2,
   .white h3,
   .white h4,
   .white h5,
   .white h6 {
       color: #fff;
   }
   
   .white p {
       color: #fff;
   }
.section-head h5 {
    font-size: 16px;
    font-weight: 600;
    color: #fd5a1e ;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
}
.section-head h3 {
    text-transform: none;
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 0;
    @media only screen and (max-width: 767px) {
        font: normal normal bold 30px/36px Arvo !important;
        margin-bottom: 0px !important;
        max-width: 90%;
    }
}
.section-head h2 {
    text-transform: capitalize;
    font-size: 39px;
    margin-bottom: 20px;
}
.section-head-bar {
    position: relative;
    display: block;
}
.section-head h2 span {
    text-transform: capitalize;
    color: #fd5a1e ;
}
.section-head-bar::before {
    content: "";
    position: absolute;
    left: 5px;
    bottom: 0;
    width: 40px;
    height: 4px;
    background: #fd5a1e ;
    transform: skewX(
45deg);
}
.section-head-bar::after {
    content: "";
    position: absolute;
    left: 10px;
    bottom: 0;
    width: 40px;
    height: 4px;
    background: #fd5a1e ;
    transform: skewX(
-45deg);
}
.section-head p {
    color: #616161;
    line-height: 1.5;
    padding: 25px 0 0;
    margin: 0;
    font-family: 'Open+Sans', sans-serif !important;
    @media only screen and (max-width: 767px) {
        font-family: "Open+Sans", sans-serif !important;
        font-size: 18px;
        line-height: 30px;
        font-weight: normal;
        padding-top: 10px;
  
    }
}
.common-video.style-5 {
    margin-top: 25px;
}
.video-icon {
    display: inline-block;
} 

@-webkit-keyframes ripple-yellow {
    0% {
     //   -webkit-box-shadow: 0 0 0 0 rgb(255, 60, 0, 0.3), 0 0 0 10px rgb(255, 60, 0, 0.3), 0 0 0 20px rgb(255, 60, 0, 0.3);
        box-shadow: 0 0 0 0 rgba(255, 60, 0, 0.3), 0 0 0 10px rgba(255, 60, 0, 0.3), 0 0 0 rgba(255, 60, 0, 0.3)
    }
    100% {
       // -webkit-box-shadow: 0 0 0 10px rgb(255, 60, 0, 0.3), 0 0 0 20px rgb(255, 60, 0, 0.3), 0 0 0 30px rgb(255, 60, 0, 0.3);
        box-shadow: 0 0 0 10px rgba(255, 60, 0, 0.3), 0 0 0 20px rgba(255, 60, 0, 0.3), 0 0 0 30px rgba(255, 60, 0, 0)
    }
}

@keyframes ripple-yellow {
    0% {
       // -webkit-box-shadow: 0 0 0 0 rgb(255, 60, 0, 0.3), 0 0 0 10px rgb(255, 60, 0, 0.3), 0 0 0 20px rgb(255, 60, 0, 0.3);
        box-shadow: 0 0 0 0 rgba(255, 60, 0, 0.3), 0 0 0 10px rgba(255, 60, 0, 0.3), 0 0 0 20px rgba(255, 60, 0, 0.3)
    }
    100% {
      //  -webkit-box-shadow: 0 0 0 10px rgb(255, 60, 0, 0.3), 0 0 0 20px rgb(255, 60, 0, 0.3), 0 0 0 30px rgb(255, 60, 0, 0);
        box-shadow: 0 0 0 10px rgba(255, 60, 0, 0.3), 0 0 0 20px rgba(255, 60, 0, 0.3), 0 0 0 30px rgba(255, 60, 0, 0)
    }
}
.video-icon a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    position: relative;
    color: #fff;
    -webkit-animation: ripple-white 1s linear infinite;
    animation: ripple-blue 1s linear infinite;
    -webkit-transition: .5s;
    background: #fd5a1e ;
    font-size: 28px;
    z-index: 1;
}

.common-video .video-icon a,
.choose-us-video .video-icon a,
.section-video .video-icon a {
	width: 85px;
	height: 85px;
	line-height: 94px;
	color: #fd5a1e ;
	animation: ripple-yellow 1s linear infinite;
	background: #fff;
    @media only screen and (max-width: 767px){
        animation:none !important;
        width: 35px;
	height: 35px;
	line-height: 44px;
    }
  
}
.video-icon span{
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    padding-left: 26px;
}
.service-section {
    background: #f4f7fc;
}
.service-single-items {
    margin-bottom: 35px;
}
.service-number {
    color: #fd5a1e ;
    font-size: 20px;
    font-weight: 600;
    position: relative;
    margin-right: 50px;
    line-height: 2;
}
.service-number {
    color: #fd5a1e ;
    font-size: 20px;
    font-weight: 600;
    position: relative;
    margin-right: 50px;
    line-height: 2;
}
.service-number::after {
    content: "";
    height: 1px;
    width: 30px;
    background: #fd5a1e ;
    position: absolute;
    top: 18px;
    left: 32px;
}
.service-single-items h2 {
    position: relative;
    font-size: 25px;
    margin-bottom: 30px;
}
.service-single-items h2::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 60px;
    background: #999;
    bottom: -15px;
    left: 0;
}
.service-text p {
    margin: 21px 0;
    font-family: 'Open+Sans', sans-serif !important;
    color: #1a1a1a;
    font-size: 16px;
    line-height: 23px;
}
.service-catagory ul li {
    list-style: none;
    transition: .3s;
    padding: 5px 0;
}
.service-catagory ul li a {
    text-decoration: none;
    color: #1a1a1a;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    transition: .3s;
}
.service-catagory ul li:hover a {
    color: #fd5a1e ;
    padding-left: 15px;
    transition: .3s;
}
.choose-us-section {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.choose-us-thumb img {
    width: 100%;
    height: auto;
}
.choose-us-section p {
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Open+Sans', sans-serif !important;
    line-height: 28px;
    font-size: 19px;
    
}

.section-head.style-2{
    //background: linear-gradient(360deg, rgba(255, 60, 0, 0.87), rgba(241, 141, 1, 0.87)), #f4f7fc url(assets/images/case-study/pd-sh.jpg) center center/cover no-repeat scroll;
  }
  .section-head h5 {
    font-size: 16px;
    font-weight: 600;
    color: #fd5a1e ;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
}
.service-single-items .service-text p {
    min-height: 95px;
}
.team-section.style-5 {
    background: #f4f7fc !important;
    margin-top: 0 !important;
}
.section-head h5 {
    font-size: 16px;
    font-weight: 600;
    color: #fd5a1e ;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;
    margin-bottom: 5px;
}
.section-head h2 {
    text-transform: capitalize;
    font-size: 39px;
    margin-bottom: 20px;
}
.section-head h2 span {
    text-transform: capitalize;
    color: #fd5a1e ;
}

.single-team-member {
    position: relative;
    text-align: center;
    transition: .3s;
  }
  .team-member-thumb{
    position: relative;
  }
  .team-member-thumb img {
    transition: .3s;
    width: 100%;
  }
  .single-team-member:hover .team-member-thumb img {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px 0 rgba(207, 228, 255, 0.8);
  }
  .single-team-member:hover .team-member-intro-section{
    transform: translateY(-10px);
  }
  .team-member-thumb:after {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fd5a1e ;
    z-index: 1;
    opacity: 0;
    transition: .3s;
  }
  .single-team-member:hover .team-member-thumb:after  {
    opacity: .7;
    transform: translateY(-10px);
  }
  .team-member-social-icon ul{
    position: absolute;
    top: 30%;
    right: -10px;
    background: #fff;
    padding: 15px !important;
    transition: .3s;
    opacity: 0;
    z-index: 1;
  }
  .team-member-social-icon ul li{
   list-style: none;
   padding: 4px 0;
  }
  .team-member-social-icon ul li a{
    color: #fd5a1e ;
  }
  .single-team-member:hover .team-member-social-icon ul {
    position: absolute;
    right: -1px;
    opacity: 1;
  }
  .team-member-intro-section {
    background: #fff;
    padding:20px 0;
    width: 80%;
    margin: auto;
    border-radius: 5px;
    margin-top: -40px;
    position: relative;
    box-shadow: 0 0 15px 0 rgba(32,46, 0,.06);
    transition: .3s;
    z-index: 1;
  }
  .team-member-intro-section h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    transition: .5s;
  }
  .team-member-intro-section h2:hover {
    color: #fd5a1e ;
  }
  .team-member-intro-section p{
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 0;
    letter-spacing: 1px;
    color: #232323;
    text-align: center;
  }
  .single-team-member:hover .team-member-social-icon ul {
    position: absolute;
    right: -1px;
    opacity: 1;
  }
  .about-section {
    @media only screen and (max-width: 767px)  {
        padding-top: 9%;
    }
  }
  @media only screen and (max-width: 767px) {
    .common-video.style-5{
        margin-top: 10px !important;
        margin-bottom: 9% !important;
    }
    .about-section , .choose-us-section , .counter-section.style-5, .services-section.style-3{
        padding-bottom: 9% !important;
        padding-top: 9% !important;
    }
    .content-single-items.d-flex.pb-30{
        flex-direction: row;
    }
    .content-single-items.d-flex{
        flex-direction: row-reverse;
    }
    .content-inner p , .content-inner h2 , .content-single-items .content-inner {
        text-align: left !important;
        margin-bottom: 10px !important;
    }
    .content-single-items {
        margin-bottom: 10px !important;
    }
    .choose-us-section .section-thumb img{
        display: none;
    }
    .counter-section.style-5 .counter-container.style-3{
        margin-top: 0 !important;
    }
  }
  .team-section , .call-project  {
      display: none !important;
  }
  /*team section style two*/
  .choose-us-section .section-thumb img{
      border: none !important;
      box-shadow: none !important;
  }