$pimary-black: #101010;
$pimary-orange: #fd5a1e;
$primary-white: #ffffff;
.val-clients {
  padding: 7% 1% 5% 1%;
  background-color: $primary-white;
  @media only screen and (max-width: 767px)  {
    img{
      margin-bottom: 0 !important;
    }
    padding: 9% 10px;
    .col {
      width: 33.333% !important;
      flex: auto;
      border: 1px solid #ddd;
      padding: 19px;
      display: flex;
    align-items: center;
    }
    h3{
      font: normal normal bold 30px/36px Calibri !important;
      margin-bottom: 10px !important;
    }
    p{
      font-family: "Open+Sans", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
    margin-bottom: 30px !important;
    padding-left: 0px;
    max-width: 90%;
    }
  }
}
.val-contact {
  padding: 7% 1% 5% 1%;
  background-color: $pimary-orange;
  @media only screen and (max-width: 767px) {
    padding: 9% 10px;
    h3{
      font: normal normal bold 30px/36px Calibri !important;
      margin-bottom: 10px !important;
    }
    p{
      font-family: "Open+Sans", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
    margin-bottom: 30px !important;
    padding-left: 0px;
    max-width: 90%;
    }
  }

  form {
    ::placeholder {
      color: #f4f1f1 !important;
    }
    input,
    textarea {
      text-align: left;
      font: normal normal normal 18px/23px Calibri;
      letter-spacing: 0px;
      color: #ffffff !important;
      text-transform: capitalize;
      opacity: 0.5;
      background-color: transparent;
      height: 60px;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #fff;
      padding: 0;
    }
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff !important;
}
