/*--------------------
 Choose Us Section Style Four 
--------------------*/
.content-single-items {
  margin-bottom: 40px;
}
.content-inner h2 {
  font-size: 23px;
  position: relative;
  margin-bottom: 30px;
  transition: .5s;
  font-weight: 700;

  
}
.counter-container.style-3 {
  margin-top: -100px;
  background: #fff;
  //box-shadow: 0 0 35px rgba(0 0 0 / 40%);
  box-shadow: 0 0 35px #00000066;
  padding: 40px 20px 40px 40px;
}
.content-inner h2:hover{
  color: #fd5a1e ;
}
.content-inner h2::before {
  content: '';
  position: absolute;
  right: 0;
  width: 60px;
  height: 1px;
  bottom: -15px;
  background: #d1d1d1;
  transition: .6s;
}
.content-inner .content-title-inner::before {
  content: '';
  position: absolute;
  left: 0;
  width: 60px;
  height: 1px;
  bottom: -15px;
  background: #d1d1d1;
  transition: .6s;
}
.content-inner p{
  color: #252424;
}
.service-btn {
  mask-type: 15px;
  display: inline-block;
}
.service-btn a {
  font-size: 16px;
  color: #fd5a1e ;
  text-transform: uppercase;
  transition:.5s;
}
.service-btn a i{
  opacity: 0;
}
.service-btn:hover i{
  opacity: 1;
}
.content-icon {
  font-size: 50px;
  color: #fd5a1e ;
  margin: 0 20px;
  img{
      max-width: 80px;
      width: 80px;
      height:80px;
  }
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right!important;
}
.section-thumb img {
  width: 100%;
}
canvas, img, video {
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
}



.counter-section.style-5 {
position: relative;
}
.counter-section.style-5 .counter-container.style-3 {
margin-top: -75px;
background: #fd5a1e  !important
}
.counter-section.style-5 .counter-single-item-inner .counter-text span,
.counter-section.style-5 .counter-single-item-inner .counter-icon,
.counter-section.style-5 .counter-single-item-inner .counter-title h4{
color: #fff;
}
/*counter  area style three */
.counter-icon img{
  max-width: 50px;
}
.counter-text span {
	font-weight: 600;
  font-size: 44px;
  color: #000000;
}
.counter-icon {
  font-size: 50px;
  color: #FF3C00;
  margin-right: 20px;
}
.counter-title h4 {
  font-family: 'Open+Sans', sans-serif !important;
  font-size: 21px;
  font-weight: 300;
  margin-bottom: 0;
}
.counter-section.style-three .counter-text span {
  color: #fff;
}
.counter-section.style-three .counter-title h4 {
  color: #ffffff;
}
.counter-section.style-three .counter-single-item-inner {
  text-align: center;
}
/*counter area style four*/

.counter-section.style-four .counter-content {
  background: rgba(255, 60, 0, 0.59);
  padding: 36px 0 38px;
  text-align: center;
}
.counter-section.style-four .section-head h2 {
  font-size: 35px;
  margin-bottom: 25px;
}
.counter-section.style-four .counter-text span {
  color: #fff;
}
.counter-section.style-four .counter-title h4 {
  color: #ffffff;
}
.counter-section.style-four .counter-single-item-inner {
  text-align: center;
}
/*counter area style five*/
.counter-section.style-five {
  background: #f4f7fc;
  padding: 61px 0 69px;
}



.section-head-container.style-2 h2 {
margin-bottom: 0;
}
.services-section.style-3 {
//background: #f4f7fc url(assets/images/services/svbg3.png) no-repeat center/cover;
background-size: cover;
background-position: center;
}
.services-section.style-3 .services-single-content {
  padding: 56px 29px 90px;
}
.services-section.style-3 .services-single-items {
  margin: 25px 15px 35px;
  overflow: hidden;
  transition: .5s;
 
  box-shadow: 0px 0 6px #1a2e551a;
}
.services-single-content {
background: #fff;
text-align: center;
padding: 33px 20px 40px;
position: relative;
z-index: 1;

}
.services-single-icon {
font-size: 60px;
color: #fd5a1e ;
transition: .5s;
margin-bottom: 15px;
}
.services-single-icon img{
max-width: 70px;
}
.services-content-inner p {
transition: .5s;
color: #6d6d6d;
text-align: center;
}
.services-content-inner h2 {
  font-size: 23px;
  font-weight: 600;
  margin: 5px 0 25px;
  transition: .5s;
}
.services-single-content::after {
content: "";
height: 100%;
width: 100%;
background: #fd5a1e ;
position: absolute;
left: 0;
bottom: -120%;
opacity: 0;
z-index: -1;
transition: .5s;
}

.services-single-content:hover::after{
opacity: 1;
bottom: 0;
}
.services-single-items:hover .services-single-icon i,
.services-single-items:hover .services-content-inner h2,
.services-single-items:hover .services-content-inner p{
color: #fff;
}
.service-bottom-link i {
  height: 0px;
  width: 0px;
  line-height: 0px;
  text-align: center;
  border-radius: 50%;
  font-size: 0px;
  position: absolute;
  bottom:20px;
  z-index: 2;

  right: 40px;
  margin: auto;
  opacity: 0;
  transition: .5s;
}
.services-single-items:hover .service-bottom-link i {
opacity: 1;
background: #fff;
color: #fd5a1e ;

box-shadow:  0 0 5px rgba(0, 0, 0, 0.2);
font-size: 51px;
height: 50px;
width: 50px;
line-height: 50px;
bottom:40px;
}
.service-bottom-link {
  position: relative;
  top: 25px;
}
.service-wrapper .services-single-items {
box-shadow: 0px 0 6px #1a2e551a;  
transition: .5s;
}
.services-single-icon img {
  transition: .100s;
}
.services-single-items:hover  .services-single-icon img{
  filter: brightness(100);
  transition: .100s;
}