$pimary-black: #101010;
$pimary-orange: #fd5a1e;
$primary-white: #ffffff;
  

.val-home_sec {
    min-height: 100vh;
    height: auto;
    @media only screen and (max-width: 767px) {
        height: auto !important;
        min-height: auto !important;
    }
}

.val-hero {
    background-size: 100%;
    background-attachment: scroll;
    background-repeat: no-repeat;
    width: 100%;

    padding: 7% 3% 0% 1%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .val-hero_social {
        ul{
            @media only screen and (max-width: 767px) {
                display: flex;
                justify-content: space-between;
                flex-direction: inherit;
                padding-left: 15px !important;
                padding-right: 15px;
                max-width: 50%;
                
            }
        }
        .scroll_img {
            position: absolute;
            right: 5%;
            bottom: 0;
            display: flex;
            writing-mode: vertical-rl;
            justify-content: space-between;
            @media only screen and (max-width: 767px)  {
                display: none;
            }
            color: #9dc9dd;
            img {
                margin-bottom: 10px;
                /* max-height: 90%; */
                height: 100px;
                object-fit: contain;
            }
        }
        li a {
            img{
                
                height: 21px;
                width: 21px;
                margin: 15px 0;
            }
            .val_fb{
                width: 10px;
            }
        }
    }
    .val-hero_body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 70%;
        margin: 0 auto;
        
        @media(min-width:1025px) and (max-width:1635px) {
            width: 80%;  
        }
        @media only screen and (max-width: 1025px) {
            width:90%;
        }
        @media only screen and (max-width: 767px) {
            width:100%;
            padding-top: 0px;
            padding-bottom: 10px;
        }
        h4 {
            text-align: left;
            font: normal normal 500 1.125rem/1.438rem Calibri;
            letter-spacing: 3.6px;
            color: $pimary-black;
            opacity: 1;
            padding-bottom: 50px;
            @media only screen and (max-width: 767px) {
                padding-bottom: 0px;
                padding-left: 15px;
                padding-bottom: 0;
                font-size: 14px;
                line-height: 14px;
            }
        }

        h1 {
            text-align: left;
            font: normal normal bold 6.25rem/8.125rem Calibri;
            @media(min-width:1025px) and (max-width:1635px)  {
                font: normal normal bold 4.25rem/6.125rem Calibri;
            }
            @media only screen and (max-width: 1200px) {
                font: normal normal bold 4.25rem/6.125rem Calibri;
            }
            @media only screen and (max-width: 767px) {
                font: normal normal bold 20px/50px Calibri;
                padding-left: 15px;
                padding-bottom: 10px;
                line-height: 30px;
                max-width: 90%;
            }

            letter-spacing: 0px;
            text-transform: uppercase;
            opacity: 1;
            padding-left: 50px;
            span {
                color: $pimary-orange;
            }
        }

        p {
            color: var(--unnamed-color-96b9c9);
            text-align: left;
            font: normal normal normal 1.875rem/2.813rem Calibri;
            letter-spacing: 0px;
            color: #0d4a66;
            opacity: 1;
            padding-left: 50px;
            max-width: 860px;
            margin-bottom: 0;
            @media only screen and (max-width: 767px) {
                line-height: 25px;
                font-size: 16px ;
                max-width: 84%;
                padding-left: 15px;
            }
        }
    }
}


