.val-portfolio {
    padding: 8% 0 5% 0%;
    background-color: #101010;
    @media only screen and (max-width: 767px) {
        padding: 9% 5px;
    }

    .val-portfolio_body {
        img {
            transition: 0.5s;
        }
        .val-portfolio_body_card_item:hover {
            img {
                transform: scale(95%);
                transition: 0.5s;
            }
            h4 {
                transition: 0.5s;
                margin-left: 20px;
            }
        }
        h4 {
            text-align: left;
            font: normal normal 600 18px/23px Arvo;
            letter-spacing: 3.6px;
            color: #ffffff;
            text-transform: uppercase;
            opacity: 1;
        }
        h3 {
            text-align: left;
            font: normal normal bold 50px/65px Arvo;
            letter-spacing: 0px;
            text-transform: none;
            opacity: 1;
            color: #ffffff;
            @media only screen and (max-width: 767px)  {
                padding-left: 0px;
                font: normal normal bold 30px/36px Arvo !important;
                margin-bottom: 10px !important;
            }
           
        }
        .val-portfolio_body_card {
            .row.mt-5 {
                @media only screen and (max-width: 767px){
                    margin-top: 0 !important;
                }
            }
            h4 {
                text-align: left;
                font: normal normal normal 25px/45px Arvo;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
                transition: 0.5s;
            }
            @media only screen and (max-width: 767px) {
                .val-portfolio_body_card_item {
                    flex: 0 0 auto;
                    width: 100%;
                    margin-bottom: 30px;
                }
            }
           
        }
    }
}
.val-feature {
    padding: 5% 0 5% 0%;
    @media only screen and (max-width: 767px) {
        padding: 8% 4% 8% 4%
    }
   ;
}
